<template>
  <div class="relative w-full h-auto my-10">
    <img :src="everything_zoomed_out" alt="" class="w-full h-full" />
    <div
      class="absolute inset-0 bg-gradient-to-r from-access/70 to-purple-950/70 pointer-events-none"
    ></div>
    <div class="absolute inset-0 grid grid-cols-4 divide-x-2 h6:py-2 py-1">
      <div
        v-for="(impact, index) in impactMap"
        :key="impact.navigation"
        class="h-fit self-start hover:underline decoration-white underline-offset-4 z-10 cursor-pointer"
        :class="{ 'self-end': index > 3 }"
        @click="navigateTo(impact.navigation)"
      >
        <h1
          class="text-white k1:text-lg h8:text-xs text-[6px] leading-none font-bold text-center"
        >
          {{ impact.title }}
        </h1>
      </div>
    </div>
    <div
      class="grid grid-cols-4 place-items-center k1:px-48 h6:px-24 px-8 gap-y-2 justify-center h-full absolute top-0 w-full"
    >
      <h1 class="k1:text-6xl h8:text-4xl text-xl text-white absolute">
        Quantum Impact Areas
      </h1>
      <div
        v-for="impact in impactMap"
        :key="impact.navigation"
        class="k1:w-40 h8:w-36 w-16 aspect-square relative group cursor-pointer rounded-full border-2 hover:scale-110 hover:z-10 transition-all duration-300"
        @click="navigateTo(impact.navigation)"
      >
        <!--        <p-->
        <!--          class="text-white backdrop-blur p-1 rounded-full leading-none w-full k1:text-xs text-[8px] z-10 absolute opacity-0 group-hover:opacity-100 -left-1/2 translate-x-1/2 group-hover:translate-y-2 translate-y-0 duration-300 text-center"-->
        <!--        >-->
        <!--          {{ impact.title }}-->
        <!--        </p>-->
        <img
          :src="impact.img"
          :alt="impact.title"
          class="object-cover absolute inset-0 z-0 select-none h-full group-hover:object-left transition-all duration-500 rounded-full"
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import everything_zoomed_out from "@/assets/images/Impact/everything_zoomed_out.png";
import router from "@/router";
import { left } from "core-js/internals/array-reduce";
import { impactMap } from "@/views/Impact/Data/impactMap";

export default {
  name: "impacts-image",
  methods: { left },
  setup() {
    const navigateTo = (navigation) => {
      router.push({ name: navigation });
    };
    return {
      navigateTo,
      impactMap,
      everything_zoomed_out,
    };
  },
};
</script>
